<template>
  <div>
    <v-layout class="emb-card mb-6">
      <app-card colClasses="xl12 lg12 md12 xs12 sm12" contentCustomClass="pa-6">
        <buy-or-sell></buy-or-sell>
      </app-card>
    </v-layout>
  </div>
</template>

<script>
import BuyOrSell from "Components/Widgets/BuyOrSell";

export default {
  components: {
    BuyOrSell,
  },
};
</script>